@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?2dgxgf');
  src: url('../fonts/icomoon.eot?2dgxgf#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?2dgxgf') format('truetype'), url('../fonts/icomoon.woff?2dgxgf') format('woff'), url('../fonts/icomoon.svg?2dgxgf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-pacte:before {
  content: "\e907";
}
.icon-money:before {
  content: "\e90a";
}
.icon-worker:before {
  content: "\e90b";
}
.icon-certificate:before {
  content: "\e900";
}
.icon-roof:before {
  content: "\e901";
}
.icon-check:before {
  content: "\e902";
}
.icon-phone:before {
  content: "\e903";
}
.icon-invalid:before {
  content: "\e904";
}
.icon-menu:before {
  content: "\e905";
}
.icon-valid:before {
  content: "\e906";
}
.icon-arrow:before {
  content: "\e908";
}
.icon-caret:before {
  content: "\e909";
}
html body section#intro {
  min-height: calc(100vh - 75px);
}
html body section#intro #intro_content .intro_text h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 0;
  padding: 15px 0;
}
html body section#intro #intro_content .intro_text .args {
  font-style: italic;
}
html body section#intro #intro_content .intro_text .args li {
  display: flex;
  font-weight: 600;
}
html body section#intro #intro_content .intro_text .args i {
  color: #ffa301;
}
html body section#intro .brand-section {
  background: #f0f0f0;
}
html body section#intro .brand-section img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  html body section#intro .args {
    font-size: 16px;
    text-align: left;
  }
  html body section#intro .args li {
    padding: 4px 0;
  }
  html body section#intro h3 {
    font-size: 16px;
  }
  html body section#intro .brand-section img {
    max-height: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body section#intro .args {
    font-size: 18px;
  }
  html body section#intro .args li {
    padding: 5px 0;
    justify-content: center;
  }
  html body section#intro .args i {
    display: none;
  }
  html body section#intro h3 {
    font-size: 18px;
  }
}
@media (max-width: 991px) {
  html body section#intro #intro_content {
    text-align: center;
    padding-top: 50px;
  }
}
@media (min-width: 992px) {
  html body section#intro .args {
    font-size: 18px;
  }
  html body section#intro .args li {
    padding: 6px 0;
  }
  html body section#intro #intro_content {
    padding-top: 110px;
  }
  html body section#intro h2 {
    max-width: 500px;
  }
  html body section#intro h3 {
    font-size: 20px;
  }
  html body section#intro .brand-section {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
html body section #simulator .simulator-body {
  background-color: #fff;
}
html body section #simulator .form-heading {
  text-align: left;
}
html body section #simulator .form-heading .engagement {
  display: flex;
  align-items: center;
}
html body section #simulator .form-heading .engagement i {
  font-size: 45px;
}
html body section #simulator .form-heading .engagement h3 {
  font-style: italic;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
html body section #simulator .form-heading .engagement .green {
  color: #6eb225;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1.1em;
}
html body section #simulator .footer-simu {
  color: #333333;
  background-color: rgba(255, 255, 255, 0.7);
}
html body section #simulator .footer-simu .brand-wrapper {
  display: flex;
  align-items: center;
}
html body section #simulator .footer-simu .brand-wrapper > div {
  flex: 1;
}
html body section #simulator .footer-simu span {
  font-style: italic;
  font-size: .9em;
  font-weight: 700;
  margin-bottom: 10px;
  display: block;
}
@media (min-width: 992px) {
  html body section #simulator .footer-simu {
    padding: 15px 40px;
  }
}
@media (min-width: 768px) {
  html body section #simulator .form-heading h3 {
    font-size: 16px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body section #simulator .footer-simu {
    padding: 10px 20px;
  }
}
@media (max-width: 767px) {
  html body section #simulator .form-control {
    height: 34px;
    margin-bottom: 10px;
  }
  html body section #simulator .form-heading i,
  html body section #simulator .form-heading .green {
    display: none;
  }
  html body section #simulator .form-heading h3 {
    color: #ffa301;
    font-size: 14px;
  }
  html body section #simulator .footer-simu {
    padding: 10px;
  }
  html body section #simulator .footer-simu .brand-wrapper img {
    max-width: 45px;
  }
}
@media (min-width: 768px) {
  html body section#about {
    padding: 50px 0;
  }
  html body section#about #tabs-holder ul.nav-tabs > li:first-child {
    border-right: 1px solid #999;
    padding-right: 20px;
  }
  html body section#about #tabs-holder ul.nav-tabs > li:last-child {
    padding-left: 20px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body section#about img.img-responsive {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 767px) {
  html body section#about {
    padding: 30px 0;
  }
  html body section#about #tabs-holder ul.nav-tabs {
    text-align: center;
  }
  html body section#about #tabs-holder ul.nav-tabs > li {
    float: none;
  }
}
html body section#features .infographie {
  text-align: center;
}
html body section#features .infographie img {
  margin: 0 auto;
}
html body section#features .features-text h4 {
  font-family: "Open Sans", sans-serif;
}
html body section#features .icon-box {
  display: flex;
}
@media (min-width: 992px) {
  html body section#features {
    padding: 70px 0;
  }
  html body section#features .infographie {
    margin-bottom: 90px;
  }
  html body section#features h4 {
    margin-bottom: 25px;
  }
  html body section#features i {
    font-size: 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body section#features {
    padding: 50px 0;
  }
  html body section#features .infographie {
    margin-bottom: 70px;
  }
  html body section#features .infographie img {
    max-width: 680px;
  }
  html body section#features .features-box {
    display: flex;
  }
  html body section#features .features-box .icon-box {
    margin-right: 30px;
  }
  html body section#features .features-box h4 {
    margin-bottom: 16px;
  }
  html body section#features .features-box i {
    font-size: 30px;
  }
}
@media (min-width: 768px) {
  html body section#features h4 {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  html body section#features {
    padding: 35px 0;
  }
  html body section#features .infographie {
    margin: 30px 0;
  }
  html body section#features h4 {
    margin-bottom: 15px;
    font-size: 16px;
  }
}
@media (min-width: 768px) {
  html body section#clients {
    padding: 60px 0;
  }
}
@media (max-width: 767px) {
  html body section#clients {
    padding: 30px 0;
  }
  html body section#clients img {
    max-width: 65px;
  }
}
html body section#faq #accordion .panel .panel-heading {
  background-color: #fff;
}
html body section#faq #accordion .panel .panel-heading .panel-title {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #4c4c4c;
}
html body section#faq #accordion .panel .panel-heading .panel-title > a {
  display: flex;
  align-items: center;
  transition: all .3s;
}
html body section#faq #accordion .panel .panel-heading .panel-title > a:hover {
  color: #ffa301;
}
html body section#faq #accordion .panel .panel-body .subtitle {
  font-weight: 600;
  color: #4c4c4c;
  margin-bottom: 10px;
  display: block;
}
html body section#faq #accordion .panel .flex-wrapper {
  display: flex;
  align-items: center;
}
@media (min-width: 992px) {
  html body section#faq #accordion .panel-body {
    padding-bottom: 30px;
    line-height: 1.6em;
  }
  html body section#faq #accordion .panel-title {
    font-size: 20px;
  }
  html body section#faq #accordion .flex-wrapper img {
    margin-right: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body section#faq #accordion .panel-title {
    font-size: 18px;
  }
}
@media (max-width: 767px) {
  html body section#faq #accordion .panel-title {
    font-size: 15px;
  }
  html body section#faq #accordion .panel-title i {
    display: none;
  }
  html body section#faq #accordion .panel-body {
    padding: 15px 0;
  }
  html body section#faq #accordion #ztd-revenus .panel-body {
    padding: 15px;
  }
}
@media (max-width: 991px) {
  html body section#faq #accordion .flex-wrapper {
    flex-direction: column-reverse;
  }
  html body section#faq #accordion .panel-body {
    line-height: 1.6em;
  }
}
html body section#faq #accordion .revenus#tab-revenus {
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
}
html body section#faq #accordion .revenus#tab-revenus tr th {
  padding: 20px;
  text-align: center;
  font-weight: 400;
  background: #304966;
  color: #FFF;
}
html body section#faq #accordion .revenus#tab-revenus tr th span {
  display: block;
}
html body section#faq #accordion .revenus#tab-revenus tr th span.italic {
  line-height: .8em;
  font-weight: 300;
}
html body section#faq #accordion .revenus#tab-revenus tr td {
  text-align: center;
}
html body section#faq #accordion .revenus#tab-revenus tr:nth-child(odd) {
  background: #f1ebeb;
}
html body section#faq #accordion .revenus#tab-revenus tr:nth-child(even) {
  background: #f7f5f5;
}
@media (min-width: 992px) {
  html body section#faq #accordion .revenus#tab-revenus {
    min-width: 820px;
  }
}
html body section#faq #accordion .revenus#ztd-revenus {
  font-family: "Open Sans", sans-serif;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  margin: 15px auto;
}
html body section#faq #accordion .revenus#ztd-revenus .panel {
  margin-bottom: 0;
}
html body section#faq #accordion .revenus#ztd-revenus .panel + .panel {
  margin-top: 0;
}
html body section#faq #accordion .revenus#ztd-revenus .panel .panel-title,
html body section#faq #accordion .revenus#ztd-revenus .panel a {
  font-size: 16px;
  font-family: "Open Sans", sans-serif;
  text-transform: none;
  text-align: center;
  font-weight: 600;
  display: block;
}
html body section#faq #accordion .revenus#ztd-revenus .panel .panel-heading {
  transition: all .3s;
  background: #ececec;
}
html body section#faq #accordion .revenus#ztd-revenus .panel .panel-heading.active,
html body section#faq #accordion .revenus#ztd-revenus .panel .panel-heading:hover {
  background: #304966;
}
html body section#faq #accordion .revenus#ztd-revenus .panel .panel-heading.active .panel-title,
html body section#faq #accordion .revenus#ztd-revenus .panel .panel-heading:hover .panel-title {
  color: #fff;
}
html body section#faq #accordion .revenus#ztd-revenus .panel .block-numbers span {
  display: flex;
  justify-content: space-between;
}
html body section#faq #accordion .revenus#ztd-revenus .panel.special .panel-title {
  font-style: italic;
}
@media (min-width: 992px) {
  html body section#faq {
    padding: 85px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body section#faq {
    padding: 60px 0;
  }
}
@media (max-width: 767px) {
  html body section#faq {
    padding: 45px 0;
  }
}
html body #statistic_banner {
  color: #FFF;
}
html body #statistic_banner .block-quote {
  text-align: center;
}
html body #statistic_banner .block-quote .quote {
  font-family: "Oswald", sans-serif;
  text-transform: uppercase;
}
html body #statistic_banner .extrait .author {
  font-weight: 600;
  font-style: italic;
}
@media (min-width: 992px) {
  html body #statistic_banner {
    padding: 75px 0;
  }
  html body #statistic_banner .quote {
    font-size: 30px;
    margin-bottom: 45px;
  }
  html body #statistic_banner .extrait {
    line-height: 1.8em;
  }
}
@media (max-width: 991px) {
  html body #statistic_banner .quote {
    margin-top: 30px;
  }
  html body #statistic_banner .extrait {
    text-align: center;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body #statistic_banner {
    padding: 50px 0;
  }
  html body #statistic_banner .quote {
    font-size: 28px;
    margin-bottom: 30px;
  }
  html body #statistic_banner .extrait {
    line-height: 1.6em;
  }
}
@media (max-width: 767px) {
  html body #statistic_banner {
    padding: 30px 0;
  }
  html body #statistic_banner .quote {
    font-size: 24px;
    margin-bottom: 25px;
  }
  html body #statistic_banner .extrait {
    line-height: 1.5em;
  }
}
html body #testimonials .btn {
  margin-top: 30px;
}
@media (min-width: 992px) {
  html body #testimonials {
    padding: 70px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body #testimonials {
    padding: 50px 0;
  }
  html body #testimonials .testi-content {
    min-height: 162px;
  }
}
@media (max-width: 767px) {
  html body #testimonials {
    padding: 35px 0;
  }
  html body #testimonials .testi-content {
    margin: 0 0 20px;
  }
}
html body footer#footer .logo-footer img {
  max-width: 165px;
}
html body footer#footer p {
  margin: 0;
}
html body footer#footer a {
  color: #FFF;
}
@media (min-width: 768px) {
  html body footer#footer {
    padding: 15px 0;
  }
}
@media (max-width: 767px) {
  html body footer#footer {
    padding: 10px 0;
  }
  html body footer#footer #footer_copyright p {
    font-size: 12px;
  }
}
html body .titlebar h2 {
  color: #4c4c4c;
}
html body .titlebar h2:after {
  background: #ffa301;
}
html body .bold {
  font-weight: 600;
}
html body .italic {
  font-style: italic;
}
html body .inline-block {
  display: inline-block;
}
html body .orange {
  color: #ffa301;
}
html body .underline {
  position: relative;
}
html body .underline:after {
  content: "";
  border-bottom: 1px solid #fff;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
@media (min-width: 992px) {
  html body .underline:after {
    width: 100%;
  }
}
@media (max-width: 991px) {
  html body .underline:after {
    max-width: 230px;
    margin: 0 auto;
  }
}
@media (min-width: 992px) {
  html body .titlebar h2 {
    margin-bottom: 60px;
    font-size: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  html body .titlebar h2 {
    margin-bottom: 45px;
    font-size: 26px;
  }
}
@media (max-width: 767px) {
  html body .titlebar h2 {
    margin-bottom: 25px;
    font-size: 20px;
  }
  html body #scrollUp {
    display: none !important;
  }
}
