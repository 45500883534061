@import "vars-icofont";

@font-face {
  font-family: 'icomoon';
  src:  url('@{icomoon-font-path}/icomoon.eot?2dgxgf');
  src:  url('@{icomoon-font-path}/icomoon.eot?2dgxgf#iefix') format('embedded-opentype'),
    url('@{icomoon-font-path}/icomoon.ttf?2dgxgf') format('truetype'),
    url('@{icomoon-font-path}/icomoon.woff?2dgxgf') format('woff'),
    url('@{icomoon-font-path}/icomoon.svg?2dgxgf#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-pacte {
  &:before {
    content: @icon-pacte;
  }
}
.icon-money {
  &:before {
    content: @icon-money;
  }
}
.icon-worker {
  &:before {
    content: @icon-worker;
  }
}
.icon-certificate {
  &:before {
    content: @icon-certificate;
  }
}
.icon-roof {
  &:before {
    content: @icon-roof;
  }
}
.icon-check {
  &:before {
    content: @icon-check;
  }
}
.icon-phone {
  &:before {
    content: @icon-phone;
  }
}
.icon-invalid {
  &:before {
    content: @icon-invalid;
  }
}
.icon-menu {
  &:before {
    content: @icon-menu;
  }
}
.icon-valid {
  &:before {
    content: @icon-valid;
  }
}
.icon-arrow {
  &:before {
    content: @icon-arrow;
  }
}
.icon-caret {
  &:before {
    content: @icon-caret;
  }
}


