@import 'icofont.less';
@import 'vars.less';

html body {
  section {
    // INTRO SECTION
    &#intro {
      min-height: ~"calc(100vh - 75px)";
      #intro_content {
        .intro_text {
          h3 {
            font-family: 'Open Sans', sans-serif;
            font-weight: 600;
            display: inline-block;
            margin-bottom: 0;
            padding: 15px 0;
          }
          .args {
            font-style: italic;
            li {
              display: flex;
              font-weight: 600;
            }
            i {
              color: @orange;
            }
          }
        }
      }
      .brand-section {
        background: @grey-bg;
        img {
          max-width: 100%;
          height: auto;
        }
      }

      @media @smdwn {
        .args {
          font-size: 16px;
          text-align: left;
          li {
            padding: 4px 0;
          }
        }

        h3 {
          font-size: 16px;
        }

        .brand-section {
          img {
            max-height: 50px;
          }
        }
      }
      @media @sm {
        .args {
          font-size: 18px;
          li {
            padding: 5px 0;
            justify-content: center;
          }
          i {
            display: none;
          }
        }

        h3 {
          font-size: 18px;
        }
      }
      @media @mddwn {
        #intro_content {
          text-align: center;
          padding-top: 50px;
        }
      }
      @media @mdup {
        .args {
          font-size: 18px;
          li {
            padding: 6px 0;
          }
        }

        #intro_content {
          padding-top: 110px;
        }

        h2 {
          max-width: 500px;
        }

        h3 {
          font-size: 20px;
        }

        .brand-section {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
        }
      }
    }

    #simulator {

      .simulator-body {
        background-color: #fff;
      }
      .form-heading {
        text-align: left;
        .engagement {
          display: flex;
          align-items: center;
          i {
            font-size: 45px;
          }
          h3 {
            font-style: italic;
            font-family: @open-sans;
            font-weight: 600;
          }
          .green {
            color: darken(@green-light, 10%);
            font-weight: 800;
            text-transform: uppercase;
            font-size: 1.1em;
          }
        }

      }
      .footer-simu {
        color: @grey-text;
        background-color: rgba(255, 255, 255, .7);
        .brand-wrapper {
          display: flex;
          align-items: center;
          & > div {
            flex: 1;
          }
        }
        span {
          font-style: italic;
          font-size: .9em;
          font-weight: 700;
          margin-bottom: 10px;
          display: block;
        }

      }
      @media @mdup {
        .footer-simu {
          padding: 15px 40px;
        }
      }
      @media @smup {
        .form-heading {
          h3 {
            font-size: 16px;
          }
        }
      }
      @media @sm {
        .footer-simu {
          padding: 10px 20px;
        }
      }
      @media @smdwn {
        .form-control {
          height: 34px;
          margin-bottom: 10px;
        }

        .form-heading {
          i, .green {
            display: none;
          }
          h3 {
            color: @orange;
            font-size: 14px;
          }
        }

        .footer-simu {
          padding: 10px;
          .brand-wrapper {
            img {
              max-width: 45px;
            }
          }
        }
      }
    }

    &#about {
      @media @smup {
        padding: 50px 0;

        #tabs-holder {
          ul.nav-tabs {
            > li {
              &:first-child {
                border-right: 1px solid #999;
                padding-right: 20px;
              }
              &:last-child {
                padding-left: 20px;
              }
            }
          }
        }
      }
      @media @sm {
        img.img-responsive {
          width: 100%;
          height: auto;
        }
      }
      @media @smdwn {
        padding: 30px 0;
        #tabs-holder {
          ul.nav-tabs {
            text-align: center;
            > li {
              float: none;
            }
          }
        }
      }
    }

    &#features {
      .infographie {
        text-align: center;
        img {
          margin: 0 auto;
        }
      }
      .features-text h4 {
        font-family: @open-sans;
      }
      .icon-box {
        display: flex;
      }

      @media @mdup {
        padding: 70px 0;
        .infographie {
          margin-bottom: 90px;
        }

        h4 {
          margin-bottom: 25px;
        }

        i {
          font-size: 60px;
        }
      }
      @media @sm {
        padding: 50px 0;
        .infographie {
          margin-bottom: 70px;
          img {
            max-width: 680px;
          }
        }

        .features-box {
          display: flex;
          .icon-box {
            margin-right: 30px;
          }
          h4 {
            margin-bottom: 16px;
          }
          i {
            font-size: 30px;
          }
        }
      }
      @media @smup {
        h4 {
          font-size: 18px;
        }
      }
      @media @smdwn {
        padding: 35px 0;
        .infographie {
          margin: 30px 0;
        }

        h4 {
          margin-bottom: 15px;
          font-size: 16px;
        }
      }
    }

    &#clients {
      @media @smup {

        padding: 60px 0;
      }
      @media @smdwn {
        padding: 30px 0;
        img {
          max-width: 65px;
        }
      }
    }

    &#faq {
      #accordion {
        .panel {
          .panel-heading {
            background-color: #fff;
            .panel-title {
              font-family: @open-sans;
              font-weight: 600;
              color: @grey-font;
              & > a {
                display: flex;
                align-items: center;
                transition: all .3s;
                &:hover {
                  color: @orange;
                }
              }
            }
          }
          .panel-body {
            .subtitle {
              font-weight: 600;
              color: @grey-font;
              margin-bottom: 10px;
              display: block;
            }
          }
          .flex-wrapper {
            display: flex;
            align-items: center;

          }
        }
        @media @mdup {
          .panel-body {
            padding-bottom: 30px;
            line-height: 1.6em;
          }

          .panel-title {
            font-size: 20px;
          }

          .flex-wrapper {
            img {
              margin-right: 30px;
            }
          }
        }
        @media @sm {
          .panel-title {
            font-size: 18px;
          }
        }
        @media @smdwn {
          .panel-title {
            font-size: 15px;
            i {
              display: none;
            }
          }

          .panel-body {
            padding: 15px 0;
          }

          #ztd-revenus {
            .panel-body {
              padding: 15px;
            }
          }
        }
        @media @mddwn {
          .flex-wrapper {
            flex-direction: column-reverse;
          }

          .panel-body {
            line-height: 1.6em;
          }
        }
        .revenus {
          //TABLEAU MDUP
          &#tab-revenus {
            box-shadow: @shadow;
            tr {
              th {
                padding: 20px;
                text-align: center;
                font-weight: 400;
                background: @dark-blue;
                color: #FFF;
                span {
                  display: block;
                  &.italic {
                    line-height: .8em;
                    font-weight: 300;
                  }
                }
              }
              td {
                text-align: center;
              }
              &:nth-child(odd) {
                background: @grey-odd;
              }
              &:nth-child(even) {
                background: @grey-even;
              }
            }
            @media @mdup {
              min-width: 820px;
            }
          }
          //TABLEAU MDDWN
          &#ztd-revenus {
            font-family: @open-sans;
            box-shadow: @shadow;
            max-width: 500px;
            margin: 15px auto;
            .panel {
              margin-bottom: 0;
              & + .panel {
                margin-top: 0;
              }
              .panel-title, a {
                font-size: 16px;
                font-family: @open-sans;
                text-transform: none;
                text-align: center;
                font-weight: 600;
                display: block;
              }
              .panel-heading {
                transition: all .3s;
                background: @grey-tab;
                &.active, &:hover {
                  background: @dark-blue;
                  .panel-title {
                    color: #fff;
                  }
                }
              }
              .block-numbers {
                span {
                  display: flex;
                  justify-content: space-between;
                }
              }

              &.special {
                .panel-title {
                  font-style: italic;
                }
              }
            }
          }
        }
      }

      @media @mdup {
        padding: 85px 0;
      }
      @media @sm {
        padding: 60px 0;
      }
      @media @smdwn {
        padding: 45px 0;
      }
    }

  }

  #statistic_banner {
    color: #FFF;
    .block-quote {
      text-align: center;
      .quote {
        font-family: @oswald;
        text-transform: uppercase;
      }
    }
    .extrait {
      .author {
        font-weight: 600;
        font-style: italic;
      }
    }

    @media @mdup {
      padding: 75px 0;
      .quote {
        font-size: 30px;
        margin-bottom: 45px;
      }

      .extrait {
        line-height: 1.8em;
      }
    }
    @media @mddwn {
      .quote {
        margin-top: 30px;
      }

      .extrait {
        text-align: center;
      }
    }
    @media @sm {
      padding: 50px 0;
      .quote {
        font-size: 28px;
        margin-bottom: 30px;
      }

      .extrait {
        line-height: 1.6em;
      }
    }
    @media @smdwn {
      padding: 30px 0;
      .quote {
        font-size: 24px;
        margin-bottom: 25px;
      }

      .extrait {
        line-height: 1.5em;
      }
    }
  }
  #testimonials {
    .btn {
      margin-top: 30px;
    }
    @media @mdup {
      padding: 70px 0;
    }
    @media @sm {
      padding: 50px 0;
      .testi-content {
        min-height: 162px;
      }
    }
    @media @smdwn {
      padding: 35px 0;
      .testi-content {
        margin: 0 0 20px;
      }
    }
  }

  footer#footer {
    .logo-footer {
      img {
        max-width: 165px;
      }
    }
    p {
      margin: 0;
    }
    a {
      color: #FFF;
    }
    @media @smup {
      padding: 15px 0;
    }
    @media @smdwn {
      padding: 10px 0;
      #footer_copyright p {
        font-size: 12px;
      }
    }
  }

  //BASICS STYLING
  .titlebar {
    h2 {
      color: @grey-font;
      &:after {
        background: @orange;
      }
    }
  }
  .bold {
    font-weight: 600;
  }
  .italic {
    font-style: italic;
  }
  .inline-block {
    display: inline-block;
  }
  .orange {
    color: @orange;
  }
  .underline {
    position: relative;
    &:after {
      content: "";

      border-bottom: 1px solid #fff;
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      @media @mdup {
        width: 100%;
      }
      @media @mddwn {
        max-width: 230px;
        margin: 0 auto;
      }
    }
  }

  @media @mdup {
    .titlebar h2 {
      margin-bottom: 60px;
      font-size: 30px;
    }
  }
  @media @sm {
    .titlebar h2 {
      margin-bottom: 45px;
      font-size: 26px;
    }
  }
  @media @smdwn {
    .titlebar h2 {
      margin-bottom: 25px;
      font-size: 20px;
    }

    #scrollUp {
      display: none !important;
    }
  }

}